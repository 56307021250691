import { configureStore } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { apiClient } from "../api";
import companyReducer, { logout as companyLogout } from '../features/company/slice'; // Import logout from company slice
import navReducer from "../features/nav/navSlice";
import employeeReducer, { logout as employeeLogout } from "../features/employee/slice";

const store = configureStore({
  reducer: {
    company: companyReducer,
    nav: navReducer,
    employee: employeeReducer,
  },
});

export type IRootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch;

export default store;

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        const { pathname } = window.location;
        if (!['', 'forgot_password', 'reset_password', 'confirm-company', 'confirm-employee'].includes(pathname.split('/')[1])) {
          const { dispatch } = store;
          dispatch(companyLogout());
          dispatch(employeeLogout());
          localStorage.removeItem('authToken');
          window.location.href = `/`;
        }
      }
    }
    return Promise.reject(error);
  }
);