import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { IEmployee, IFailedEmployee } from '../../interfaces';
import { numberWithCommas } from '../../utils';

interface IProps {
  failed: IFailedEmployee[],
  isOpen: boolean;
  close(): void;
}

const columns: ColumnsType<IFailedEmployee> = [
  {
    title: '№',
    render: (_: any, __: any, index: number) => index + 1
  },
  {
    title: 'Нэр',
    dataIndex: 'firstName',
  },
  {
    title: 'Алдаа',
    dataIndex: 'error',
  },
  {
    title: 'Регистр',
    dataIndex: 'regNumber',
  },
  {
    title: 'Утас',
    dataIndex: 'phone',
  },
  {
    title: 'Имэйл',
    dataIndex: 'email',
  },
  {
    title: 'Ажилтнаас',
    dataIndex: 'fromSalary',
    render: (digit: number) => numberWithCommas(digit),
  },
  {
    title: 'Байгууллагаас',
    dataIndex: 'fromCompany',
    render: (digit: number) => numberWithCommas(digit),
  },
  {
    title: 'Хуримтлуулж эхэлсэн огноо',
    dataIndex: 'startedAt',
    render: (date: string) => <span title={dayjs(date).format('YYYY-MM-DD HH:mm:ss')}>{dayjs(date).format('YYYY-MM-DD')}</span>,
    sorter: {
      compare: (a: IEmployee, b: IEmployee) => (a.startedAt > b.startedAt ? -1 : 1),
      multiple: 6,
    },
  },
]

const FailedEmployees: React.FC<IProps> = ({ failed, isOpen, close }) => {
  return (
    <Modal
      title="Эдгээр ажилчдыг нэмэхэд алдаа гарлаа."
      open={isOpen}
      onOk={close}
      onCancel={close}
      width={'100%'}
    >
      <Table
        columns={columns}
        dataSource={failed} />
    </Modal>
  );
}

export default FailedEmployees;
