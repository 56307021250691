import { Card } from 'antd';
import React from 'react';

const Video = () => {
  return (
    <Card style={{ margin: 10 }}>
      <video style={{ width: '100%' }} controls>
        <source src={`${process.env.REACT_APP_API_URL}/guide.mp4`} />
      </video>
    </Card>
  );
}
export default Video;