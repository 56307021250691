import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../api";
import { ILogin } from "../../interfaces";

export const employeeLogin = createAsyncThunk(
  'employee/login',
  async (dto: ILogin, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post('/auth-employee/login', dto);
      localStorage.setItem('authToken', data.accessToken);
      return data;
    } catch (error: any) {
      if (error.response?.data) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
);

export const employeeGetMe = createAsyncThunk(
  'employee/me',
  async () => {
    const { data } = await apiClient.get('/employee/me');
    return data;
  }
);

export const getEmployee = createAsyncThunk(
  'getEmployee',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.get(`/employee/${id}`);
      return data;
    } catch (error: any) {
      if (error.response?.data) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)