import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Input, message, Modal, Row, Table } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { apiClient } from '../../api';
import { ITransaction } from '../../interfaces';
import { calculatePOC, numberWithCommas } from '../../utils';
import { G } from '@react-pdf/renderer';

const { RangePicker } = DatePicker;


const columns: ColumnsType<ITransaction> = [
  {
    title: '№',
    render: (_: any, __: any, index: number) => index + 1
  },
  {
    title: 'Үүсгэсэн огноо',
    dataIndex: 'createdAt',
    render: (data: string, record) => <a className='transaction_info' href={`/company/transactions/${record.id}`}>{dayjs(data).format('YY-MM-DD HH:mm:ss')}</a>
  },
  {
    title: 'Баталгаажсан огноо',
    dataIndex: 'confirmedAt',
    render: (data: string) => data ? dayjs(data).format('YY-MM-DD HH:mm:ss') : ''
  },
  {
    title: 'Төлөв',
    dataIndex: 'status',
  },
  {
    title: 'Тайлбар',
    dataIndex: 'description',
  },
  {
    title: 'Ажилтны хуримтлал',
    dataIndex: 'employeeSecurities',
    render: (_, record: ITransaction) => numberWithCommas(record.employeeSecurities * record.nav)
  },
  {
    title: 'Ажилтны хуримтлалын сан',
    dataIndex: 'poolSecurity',
    render: (_, record: ITransaction) => numberWithCommas(record.poolSecurity * record.nav)
  },
  {
    title: 'Байгууллагын хуримтлал',
    dataIndex: 'companySecurities',
    render: (_, record: ITransaction) => numberWithCommas(record.companySecurities * record.nav)
  },
  {
    title: 'Хуримтлуулсан',
    render: (_, record) => numberWithCommas(record.amount)
  },
  {
    title: 'Нийт хуримтлал',
    dataIndex: 'amount',
    render: (_, record) => numberWithCommas(record.balanceAmount)
  },
  {
    title: 'Нийт үр дүн',
    dataIndex: 'amount',
    render: (_, record) => {
      return (<>
        {numberWithCommas((record.balanceCompany! + record.balanceEmployee! + record.balancePool!) * record.nav + record.balanceShift!)}
        <br />
        {/* {calculatePOC(((record.balanceCompany! + record.balanceEmployee! + record.balancePool!) * record.nav + record.balanceShift!), record.balanceAmount!)}% */}
      </>)
    }
  },
  {
    title: 'Төрөл',
    dataIndex: 'type',
    render: (type: string) => <span>{type === 'buy' ? 'Орлого' : 'Зарлага'}</span>,
  },
]

const CompanyTransactions: React.FC = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState([moment().subtract(3, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);

  useEffect(() => {
    getTransactions();
  }, [range]);

  const getTransactions = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/transaction?start=${range[0]}&end=${range[1]}`);
      setTransactions(data);
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      setRange(dateStrings)
    } else {
      console.log('Clear');
    }
  };

  const toExcel = () => {
    try {
      const data = transactions.map((transaction, i) => ({
        "№": i + 1,
        "Үүсгэсэн огноо": dayjs(transaction.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        "Баталгаажсан огноо": transaction.confirmedAt ? dayjs(transaction.createdAt).format('YYYY-MM-DD HH:mm:ss') : '',
        "Төлөв": transaction.status,
        "Ажилчдын хуримтлала": (transaction.employeeSecurities * (transaction.nav * 100) / 100),
        "Ажилчдын хуримтлалын сан": (transaction.poolSecurity * (transaction.nav * 100) / 100),
        "Байгууллагын хуримтлал": (transaction.companySecurities * (transaction.nav * 100) / 100),
        "Нийт хөрөнгө оруулалт": (transaction.amount),
        "Төрөл": (transaction.type ? 'Хуримтлуулах' : 'Зарлага'),
      }))

      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
      XLSX.writeFile(workBook, 'Гүйлгээ.xlsx');

    } catch (error) {
    }
  }

  return (
    <div style={{ margin: 10 }}>
      <Card style={{ marginBottom: 10 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span id="transaction-range">
            <span style={{ marginLeft: 10, marginRight: 10 }}>Огноогоор шүүх:</span>
            <RangePicker
              defaultValue={[moment().subtract(3, 'month'), moment()]}
              onChange={onChange} />
          </span>
          {/* <Button style={{ marginLeft: 24 }} icon={<DownloadOutlined />} type='primary' onClick={toExcel}>Excel</Button> */}
          <Button id="transaction-add" style={{ marginLeft: 'auto' }} icon={<PlusOutlined />} type='primary' onClick={() => navigate('/company/transactions/new')}>Нэмж хуримтлуулах</Button>
        </div>

      </Card>
      <Table
        scroll={{ x: true }}
        dataSource={transactions}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: [50, 100, 200]
        }} />
    </div>
  );
}

export default CompanyTransactions;