import { Card, Descriptions } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { apiClient } from '../../api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getNav } from '../../features/nav/navActions';
import { ITransaction } from '../../interfaces';
import { calculatePOC, numberWithCommas } from '../../utils';
import FundChart, { IChartData, IData } from './FundChart';


const CompanyInfo = () => {
  const { loading, info, error, lastTransaction } = useAppSelector((state) => state.company);

  const dispatch = useAppDispatch();
  const { loading: navLoading, nav } = useAppSelector((state) => state.nav)
  const [transactions, setTransactions] = useState<IChartData[]>([])

  useEffect(() => {
    dispatch(getNav());
    
    const loadHistory = async () => {
      try {
        const { data } = await apiClient.get('/transaction/confirmed');
        if (data.length === 0) {
          return;
        }

        const savings: IData[] = [];
        const balance: IData[] = [];
        data.forEach((transaction: ITransaction) => {
          savings.push({
            primary: dayjs(transaction.confirmedAt!).toDate(),
            secondary: transaction.balanceAmount!,
          });

          balance.push({
            primary: dayjs(transaction.confirmedAt!).toDate(),
            secondary: (transaction.balancePool! + transaction.balanceCompany! + transaction.balanceEmployee!) * (nav),
          });
        });

        setTransactions([{
          label: 'Хуримтлал',
          data: savings,
        }, {
          label: 'Хүч',
          data: balance,
        }]);
      } catch (err) {
        console.log(err)
      }
    }

    loadHistory();
  }, [nav]);

  return (
    <div>
      <div style={{ display: 'flex', margin: 10 }}>
        <Card style={{ flex: 1, marginRight: 10 }}>
          <label style={{ fontWeight: 600, fontSize: 24 }}>Нэвтрэх хаяг</label>
          <div>{info?.email}</div>
        </Card>
        <Card style={{ flex: 1, marginRight: 10 }}>
          <label style={{ fontWeight: 600, fontSize: 24 }}>Байгууллагын регистр</label>
          <div>{info?.regNumber}</div>
        </Card>
        <Card style={{ flex: 1 }}>
          <label style={{ fontWeight: 600, fontSize: 24 }}>Хуримтлуулагч ажилтнууд</label>
          <div>{info?.employees}</div>
        </Card>
      </div>
      <div style={{ display: 'flex', margin: 10 }}>
        <Card style={{ flex: 1 }}>
          <label style={{ fontWeight: 600, fontSize: 24 }}>Хуримтлал</label>
          <div>{numberWithCommas(lastTransaction?.balanceAmount)}₮</div>
        </Card>
        <Card style={{ flex: 1, marginLeft: 10 }}>
          <label style={{ fontWeight: 600, fontSize: 24 }}>Үр дүн</label>
          <div>{numberWithCommas(Math.ceil(((lastTransaction?.balanceEmployee || 0) + (lastTransaction?.balanceCompany || 0) + (lastTransaction?.balancePool || 0)) * nav + (lastTransaction?.balanceShift || 0)))}₮</div>
          {/* <div>{calculatePOC(Math.ceil((lastTransaction?.balanceEmployee || 0 + (lastTransaction?.balanceCompany || 0) + (lastTransaction?.balancePool || 0)) * nav + (lastTransaction?.balanceShift || 0)) || 1, (lastTransaction?.balanceAmount || 1))}%</div> */}
          <div>{calculatePOC(Math.ceil((lastTransaction?.amount || 0)), (lastTransaction?.employeeSecurities || 0 + (lastTransaction?.poolSecurity || 0)),(lastTransaction?.confirmedAt || ''), nav)}%</div>
          </Card>
      </div>
      <Card style={{ margin: 10 }}>
        <Descriptions title="Байгууллагын хуримтлалаас авах дүн" bordered size='small'>
          {info?.invoiceConfig.map(config =>
            <Descriptions.Item key={config.year} label={`${config.year} сар:`}>{config.percent}%</Descriptions.Item>
          )}
        </Descriptions>
      </Card>
      {/* {transactions.length > 0 &&
        <FundChart transactions={transactions} />} */}
    </div>
  );
}

export default CompanyInfo;