import { createSlice } from "@reduxjs/toolkit"
import { IEmployee } from "../../interfaces"
import { employeeGetMe, getEmployee } from "./actions";
import { employeeLogin } from "./actions";

const employeeToken = localStorage.getItem('employeeToken') || undefined

interface IState {
  info?: IEmployee;
  loading: boolean;
  error?: string;
  data?: IEmployee;
  employeeToken?: string;
}

const initialState: IState = {
  info: undefined,
  loading: false,
  error: undefined,
  employeeToken,
}

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('employeeToken');
      state = {
        ...initialState,
        employeeToken: undefined,
      };
    }
  },
  extraReducers(builder) {

    builder.addCase(employeeLogin.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });

    builder.addCase(employeeLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.info = payload;
      state.employeeToken = payload.employeeToken;
    });

    builder.addCase(employeeLogin.rejected, (state, { payload }: { payload: any }) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(employeeGetMe.pending, (state) => {
      state.loading = false;
      state.error = undefined;
    });

    builder.addCase(employeeGetMe.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.info = payload;
    });

    builder.addCase(employeeGetMe.rejected, (state, { payload }: { payload: any }) => {
      state.loading = false;
      state.error = payload;
    });

    builder.addCase(getEmployee.pending, (state) => {
      state.loading = true;
      state.error = undefined;
      state.data = undefined;
    });

    builder.addCase(getEmployee.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });

    builder.addCase(getEmployee.rejected, (state, { payload }: { payload: any }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { logout } = employeeSlice.actions;

export default employeeSlice.reducer;
