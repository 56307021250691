import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Space, Table } from 'antd';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getNav } from '../../features/nav/navActions';
import { IEmployee, ITransactionDetail } from '../../interfaces';
import { calculatePOC, numberWithCommas } from '../../utils';

interface IProps {
  employees: (IEmployee & ITransactionDetail)[];
  loading: boolean;
  setLoading(loading: boolean): void;
}

type DataIndex = keyof IEmployee;

const Employees: React.FC<IProps> = ({ employees, loading, setLoading }) => {

  const searchInput = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const { loading: navLoading, nav, error } = useAppSelector((state) => state.nav)

  useEffect(() => {
    dispatch(getNav())
  }, []);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: DataIndex, name: string): ColumnType<IEmployee & ITransactionDetail> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`${name} хайх`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Хайх
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Арилгах
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      //@ts-ignore
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text => text,
  });

  const columns: ColumnsType<IEmployee & ITransactionDetail> = [
    {
      title: '№',
      render: (_, __, index) => index + 1
    },
    {
      title: 'Овог',
      dataIndex: 'lastName',
      ...getColumnSearchProps('lastName', 'Овгоор'),
      render: (name: string, obj: IEmployee) => <Link className='employee_lastname' to={`${obj.id}`}>{name}</Link>,
      sorter: {
        compare: (a: IEmployee, b: IEmployee) => (a.lastName > b.lastName ? - 1 : 1),
      },
    },
    {
      title: 'Нэр',
      dataIndex: 'firstName',
      ...getColumnSearchProps('firstName', 'Нэрээр'),
      render: (name: string, obj: IEmployee) => name,
      sorter: {
        compare: (a: IEmployee, b: IEmployee) => (a.firstName.localeCompare(b.firstName)),
      },
    },
    {
      title: 'Төлөв',
      dataIndex: 'status',
      sorter: {
        compare: (a: IEmployee, b: IEmployee) => (a.status.localeCompare(b.status)),
        multiple: 1,
      },
    },
    {
      title: 'Регистр',
      dataIndex: 'regNumber',
      ...getColumnSearchProps('regNumber', 'Регистрээр'),
    },
    {
      title: 'Утас',
      dataIndex: 'phone',
      ...getColumnSearchProps('phone', "Утсаар"),
    },
    {
      title: 'Ажилтнаас',
      dataIndex: 'fromSalary',
      sorter: {
        compare: (a: IEmployee, b: IEmployee) => (a.fromSalary > b.fromSalary ? -1 : 1),
        multiple: 2,
      },
      render: (digit: number) => numberWithCommas(digit),
    },
    {
      title: 'Байгууллагаас',
      dataIndex: 'fromCompany',
      render: (digit: number) => numberWithCommas(digit),
      sorter: {
        compare: (a: IEmployee, b: IEmployee) => (a.fromCompany > b.fromCompany ? -1 : 1),
        multiple: 3,
      },
    },
    {
      title: 'Ажилтны\nхуримтлал',
      dataIndex: 'balanceEmployeeSecurities',
      render: (digit: number, record) => {
        console.log(digit, record.nav)
        return (
          <div>
            <div title='Хуримтлал'>{numberWithCommas(digit * record.nav)}₮</div>
          </div>
        )
      },
      sorter: {
        compare: (a: IEmployee & ITransactionDetail, b: IEmployee & ITransactionDetail) => (a.balanceEmployeeSecurities > b.balanceEmployeeSecurities ? -1 : 1),
        multiple: 4,
      },
    },
    {
      title: 'Байгууллагын\nхуримтлал',
      dataIndex: 'balancePoolSecurity',
      render: (digit: number, record) => (
        <div>
          <div title='Хуримтлал'>{numberWithCommas(digit * record.nav)}₮</div>
        </div>
      ),
      sorter: {
        compare: (a: IEmployee & ITransactionDetail, b: IEmployee & ITransactionDetail) => (a.balancePoolSecurity > b.balancePoolSecurity ? -1 : 1),
        multiple: 5,
      },
    },
    {
      title: 'Хуримтлуулж\nэхэлсэн огноо',
      dataIndex: 'startedAt',
      render: (date: string) => <span title={dayjs(date).format('YYYY-MM-DD HH:mm:ss')}>{dayjs(date).format('YYYY-MM-DD')}</span>,
      sorter: {
        compare: (a: IEmployee, b: IEmployee) => (a.startedAt > b.startedAt ? -1 : 1),
        multiple: 6,
      },
    },
  ];

  return (
    <Table
      scroll={{ x: true }}
      dataSource={employees}
      loading={loading}
      rowKey={"id"}
      columns={columns}
      pagination={{
        defaultPageSize: 50,
        showSizeChanger: true,
        pageSizeOptions: [50, 100, 200]
      }} />
  );
}

export default Employees;