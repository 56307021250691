import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../api";

export const getNav = createAsyncThunk(
  'transaction/nav',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.get('/transaction/nav');
      return data.nav;
    } catch (error: any) {
      if (error.response?.data) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
