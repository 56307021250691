import { QuestionCircleOutlined, DollarOutlined, FolderViewOutlined, InfoCircleOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import WhiteLogo from '../../assets/images/mandal-white-scaled.png';
import { employeeGetMe } from '../../features/employee/actions';
import { logout } from '../../features/employee/slice';
import { JoyContext } from '../../providers';

const menuItems: ItemType[] = [
// {
//   key: '/employee',
//   label: 'Ажилтны мэдээлэл',
//   icon: <InfoCircleOutlined />,
// }
// ,
{
  key: '/employee',
  label: 'Миний хуримтлал',
  icon: <FolderViewOutlined />,
}, 
// {
//   key: 'instruction',
//   label: 'Заавар',
//   icon: <FolderViewOutlined />,
// },
{
  key: 'msf',
  label: 'Мандал хуримтлалын сан',
  icon: <DollarOutlined />,
},
{
  key: 'about',
  label: 'Таны хөрөнгийн менежер',
  icon: <QuestionCircleOutlined />,
},
{
  key: '/',
  label: 'Гарах',
  icon: <LogoutOutlined />,
  style: {
    color: 'red',
    position: 'absolute',
    bottom: 0,
  }
}]

const EmployeeHome: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const { loading, info, error } = useAppSelector((state) => state.employee);
  const { setShowJoyride, showJoyride } = useContext(JoyContext);
  
  useEffect(() => {
    dispatch(employeeGetMe())
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'row', width: '100vw' }} >
      <Sider
        theme='light'
        width={230}
        style={{ height: '100vh', overflow: 'hidden' }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div style={{ width: 'full', display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(collapsed => !collapsed),
          })}
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={WhiteLogo} style={{ height: 100, width: 100 }} />
          <h3 style={{ textAlign: 'center' }}>{info?.firstName}</h3>
        </div>
        <Menu
          items={menuItems}
          theme="light"
          id="menu"
          mode="inline"
          className='custom-menu'
          inlineCollapsed={collapsed}
          onClick={async (info) => {
            if (info.key === '/') {
              dispatch(logout())
            }
            if (info.key === 'instruction') {
              setShowJoyride(!showJoyride)
              return;
            }
            navigate(info.key)
          }}
          selectedKeys={[window.location.pathname.split('/').slice(0, 3).join('/')]}
        />
      </Sider>
      <Content className='main-content'>
        <Outlet />
      </Content>
    </Layout>
  );
}

export default EmployeeHome;