import { Document, Font, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import React from 'react';
import { numberWithCommas } from '../../utils';

export type InvoiceProps = {
  title: string;
  bank: string;
  accountNumber: string;
  description: string;
  name: string;
  employeeCount: number;
  amount: number;
  createdAt: string;
  type: string;
  fee: number;
  info?: any;
}

Font.register({
  family: "Roboto",
  fonts: [{
    src: `${process.env.REACT_APP_API_URL}/Roboto-Regular.woff`,
  }, {
    src: `${process.env.REACT_APP_API_URL}/Roboto-Bold.woff`,
    fontWeight: 'bold',
  }]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: 20,
    fontSize: 14,
  },
  tableRow: {
    width: '100%',
    flexDirection: "row",
    display: 'flex',
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    flex: 1,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10
  },
  table: {
    width: '100%',
    display: 'flex',
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableFirst: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    width: 20,
  },
  cellItem: {
    fontSize: 12,
  },
  cellHeader: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  header: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  stamp: {
    height: 110,
    width: 110,
    marginTop: 40,
    alignSelf: 'center',
  },
});

const Invoice: React.FC<InvoiceProps> = ({ title, bank, accountNumber, name, description, employeeCount, amount, createdAt, info, type, fee }) => {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>Нэхэмжлэх</Text>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ marginRight: 5, flex: 1 }}>Нэхэмжлэгч:</Text>
          <Text style={{ marginLeft: 5, flex: 1 }}>Төлөгч:</Text>
        </View>
        <View style={{ flexDirection: type === 'buy' ? 'row' : 'row-reverse', marginBottom: 20 }}>
          <View style={{ flex: 1, marginRight: 5 }}>
            <Text>МАНДАЛ ХУРИМТЛАЛ ХАМТЫН ХОС ХХК</Text>
            <Text>РД: 6850367</Text>
            {
              type === 'buy' && <>
                <Text>Банк: {bank}</Text>
                <Text>Дансны дугаар: {accountNumber}</Text>
              </>
            }
          </View>
          <View style={{ flex: 1, marginLeft: 5 }}>
            <Text>{info?.name}</Text>
            <Text>РД: {info?.regNumber}</Text>
          </View>
        </View>
        <Text>Нэхэмжилсэн огноо: {dayjs(createdAt).format('YYYY/MM/DD HH:mm:ss')}</Text>
        <View style={{ marginVertical: 30 }}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableFirst}>
                <Text style={styles.cellHeader}>№</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.cellHeader}>Гүйлгээний утга</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.cellHeader}>Ажилтны тоо</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.cellHeader}>Нийт дүн</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableFirst}><Text style={styles.cellItem}>1</Text></View>
              <View style={styles.tableCol}>
                <Text style={styles.cellItem}>{description}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.cellItem}>{numberWithCommas(employeeCount)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.cellItem}>{numberWithCommas(Math.abs(amount))}</Text>
              </View>
            </View>
            {
              fee > 0 &&
              <View style={styles.tableRow}>
                <View style={styles.tableFirst}><Text style={styles.cellItem}>2</Text></View>
                <View style={styles.tableCol}>
                  <Text style={styles.cellItem}>Гүйлгээний шимтгэл</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.cellItem}>1</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.cellItem}>-{fee}</Text>
                </View>
              </View>
            }
          </View>
          <View style={{ flexDirection: 'row', marginLeft: 21 }}>
            <View style={{ flex: 1, padding: 5 }} />
            <View style={[styles.tableCol, { borderLeft: 1 }]}>
              <Text style={[styles.cellItem, { fontWeight: 'bold' }]}>Нийт дүн</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.cellItem, { fontWeight: 'bold' }]}>{numberWithCommas(Math.abs(amount) - fee)}</Text>
            </View>
          </View>
        </View>
        <Text><Text style={{ fontWeight: 'bold' }}>Нэмэлт мэдээлэл:</Text> {name}</Text>
        <Image source={`${process.env.REACT_APP_API_URL}/mam-tamga.png`} style={styles.stamp} />
      </Page>
    </Document>
  );
}

export default Invoice;