import { Button, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../../api';

const ResetPassword = () => {
  const navigate = useNavigate();
  const path = window.location.pathname.split('/')

  const onSubmit = async (dto: { password: string, repassword: string }) => {
    if (dto.password !== dto.repassword && dto.password.length > 6) {
      message.error('Нууц үгээ 6-с урт тэмдэгтээр ижил оруулна уу');
      return;
    }

    try {
      await apiClient.post(`/auth-company/reset`, {
        token: path[3],
        id: path[2],
        password: dto.password
      });
      message.success('Амжилттай солигдлоо')
      setTimeout(() => {
        navigate('/')
      }, 1000);
    } catch (err: any) {
      message.error(err.response?.data?.message || err.message)
    }
  }

  return (
    <div style={{ minWidth: 450 }}>
      <div style={{ flex: 1, alignItems: 'center', display: 'flex' }}>
        <h1 style={{ fontWeight: 'bold', fontSize: 40, padding: '2rem', textAlign: 'center' }}>Нууц үг солих</h1>
      </div>
      <div style={{ alignSelf: 'center', flex: 2, width: '100%' }}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item
            label={<span>Нууц үг</span>}
            name="password"
            rules={[{ message: 'Нууц үгээ оруулна уу' }]}
          >
            <Input type='password' placeholder='Шинэ нууц үг' />
          </Form.Item>
          <Form.Item
            label={<span>Нууц үг</span>}
            name="repassword"
            rules={[{ message: 'Нууц үгээ давтан оруулна уу' }]}

          >
            <Input type='password' placeholder='Давтан оруулах' />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Сэргээх
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;