import { Button, Card, Col, DatePicker, Input, message, Modal, Row, Table } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState , ChangeEvent} from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../../api';
import { ITransactionDetail } from '../../interfaces';
import { numberWithCommas } from '../../utils';
import { getNav } from '../../features/nav/navActions';
import InvestReturnChart from './InvestReturnChart';

const { RangePicker } = DatePicker;

const TransactionList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [nav, setNav] = useState<number>(0);
  const [transactionList, setTransactionList] = useState<ITransactionDetail[]>([]);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState([moment().subtract(6, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);
  const [rate, setRate] = useState<number>(13);
  const [totalYear, setTotalYear] = useState<number>(10);
  const [monthlyDeposit, setMonthlyDeposit] = useState<number>(0);

  useEffect(() => {
    const fetchNav = async () => {
      try {
        const navValue = await dispatch(getNav());
        setNav(navValue.payload);
      } catch (error) {
        console.error('Failed to fetch nav:', error);
      }
    };

    fetchNav();
  }, [dispatch]);

  useEffect(() => {
    getTransactions();
  }, [range]);

  useEffect(() => {
    if (transactionList.length > 0) {
      const lastRecord = transactionList[0];
      setMonthlyDeposit(lastRecord.employeeSecurities*lastRecord.nav);
    }
  }, [transactionList]);

  const getTransactions = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/transaction-detail?start=${range[0]}&end=${range[1]}`);
      setTransactionList(data);
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      setRange(dateStrings)
    } else {
      console.log('Clear');
    }
  };

  const filteredData = transactionList.filter(record => record.status === 'confirmed');

  const calculateTotal = (key: keyof ITransactionDetail) => {
    let total = 0;
    transactionList.forEach(item => {
      if (typeof item[key] === 'number') {
        total += item[key] as number;
      }
    });
    return total;
  };

  const calculateAvgHoldingYield = () => {
    let total = 0;
    let cnt = transactionList.length;

    filteredData.forEach(item => {
      let currentAmount = item['employeeSecurities'] * nav
      let sub = currentAmount - item['salaryAmount'];
      let holdingPeriodYield = sub/item['salaryAmount']*100
      total += holdingPeriodYield as number;
    });
    return total/cnt;
  };

  const calculateAvgYearYield = () => {
    let total = 0;
    let cnt = transactionList.length;

    filteredData.forEach(item => {
      let currentAmount = item['employeeSecurities'] * nav
      let sub = currentAmount - item['salaryAmount'];
      let holdingPeriodYield = sub/item['salaryAmount'];

      let confirmedAt = dayjs(item['confirmedAt']);
      let currentDate = dayjs();
      let daysDifference = currentDate.diff(confirmedAt, 'day');
      let pow = 365/daysDifference;
      let yearlyYield = Math.pow(holdingPeriodYield+1, pow)-1
      let yearlyYieldPercent = yearlyYield*100;

      total += yearlyYieldPercent as number;
    });
    return total/cnt;
  };

  const calculateSumYieldAmt = () => {
    let total = 0;
    filteredData.forEach(item => {
      let currentAmount = item['employeeSecurities'] * nav
      let sub = currentAmount - item['salaryAmount'];
      let holdingPeriodYield = sub/item['salaryAmount']*100
      let yieldAmt = holdingPeriodYield*(item['salaryAmount']+item['companyAmount']);
      total += yieldAmt;
    })
    return total;
  }

  const footerContent = (
    <div className='flex font-bold'>
      <div className='w-[18%] text-left'>Нийлбэр дүн: </div>
      <div className='w-[15%] text-left'>{numberWithCommas(calculateTotal('salaryAmount'))}₮</div>
      <div className='w-[16%] text-left'>{numberWithCommas(calculateTotal('companyAmount'))}₮</div>
      <div className='w-[20%] text-left'>{calculateAvgHoldingYield().toFixed(2)}%</div>
      <div className='w-[18%] text-left'>{numberWithCommas(calculateSumYieldAmt())}₮</div>
      <div className='w-[13%] text-left'>{calculateAvgYearYield().toFixed(2)}%</div>
    </div>
  );

  const columns: ColumnsType<ITransactionDetail> = [
    {
      title: '№',
      render: (_: any, __: any, index: number) => index + 1,
      width: '3%',
    },
    {
      title: 'Үүсгэсэн огноо',
      dataIndex: 'confirmedAt',
      render: (data: string, record) => <a className='transaction_info'>{dayjs(data).format('YYYY-MM-DD HH:mm')}</a>,
      width: '15%',
    },
    {
      title: 'Ажилтнаас',
      dataIndex: 'salaryAmount',
      render: (_, record) => {
        if (record.employeeSecurities < 0) {
          const outCome = record.employeeSecurities * record.nav;
          return numberWithCommas(outCome);
        }else{
          return numberWithCommas(record.salaryAmount);
        }
      },
      width: '15%'
    },
    {
      title: 'Компаниас',
      dataIndex: 'companyAmount',
      render: (_, record) => {
        if (record.employeeSecurities < 0) {
          const outCome = record.poolSecurity * record.nav;
          return numberWithCommas(outCome);
        }else{
          return numberWithCommas(record.companyAmount);
        }
      },
      width: '15%'
    },
    {
      title: 'Барьсан хугацааны өгөөж',
      render: (_, record) => {
        if(record.employeeSecurities > 0){
        const currentAmount = record.employeeSecurities * nav
        const startAmount = record.employeeSecurities*record.nav;
        const sub = currentAmount - startAmount;
        const holdingPeriodYield = sub/startAmount*100;
        return <span>{holdingPeriodYield.toFixed(2)}%</span>;
        }
      },
      width: '20%'
    },
    {
      title: 'Нийлбэр өгөөжтэй дүн',
      render: (_, record) => {
        if(record.employeeSecurities > 0){
        const currentAmount = record.employeeSecurities * nav
        const startAmount = record.employeeSecurities*record.nav;
        const sub = currentAmount - startAmount;
        const holdingPeriodYield = sub/startAmount*100;
        const yieldAmt = (record.salaryAmount+record.companyAmount)*holdingPeriodYield;
        return <span>{numberWithCommas(yieldAmt)}</span>;
        }
      },
      width: '17%'
    },
    {
      title: 'Жилийн өгөөж',
      render: (_, record) => {
        if(record.employeeSecurities > 0){
        const currentAmount = record.employeeSecurities * nav
        const startAmount = record.employeeSecurities*record.nav;
        const sub = currentAmount - startAmount;
        const holdingPeriodYield = sub/startAmount;
        
        const confirmedAt = dayjs(record.confirmedAt);
        const currentDate = dayjs();
        const daysDifference = currentDate.diff(confirmedAt, 'day');
        const pow = 365/daysDifference;
        const yearlyYield = Math.pow(holdingPeriodYield+1, pow)-1
        const yearlyYieldPercent = yearlyYield*100;
        return <span>{yearlyYieldPercent.toFixed(2)}%</span>;
        }
      },
      width: '15%'
    },
  ]

  return (
    <div style={{ margin: 10 }}>
      <Card style={{ marginBottom: 10 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span id="transaction-range">
            <span style={{ marginLeft: 10, marginRight: 10 }}>Огноогоор шүүх:</span>
            <RangePicker
              defaultValue={[moment().subtract(6, 'month'), moment()]}
              onChange={onChange} />
          </span>
          </div>
      </Card>

      <Table
        scroll={{ x: true }}
        dataSource={filteredData}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: [5, 50, 100]
        }} 
        footer={() => footerContent}/>

        <InvestReturnChart rate={rate} monthlyDeposit={monthlyDeposit} totalYear={totalYear}/>
        
        <div className='ml-10 mt-6 flex'>
        <label className='mt-1' htmlFor="monthlyDeposit">Хуримтлал /сар/:</label>
        <Input
          id="monthlyDeposit"
          type="text"
          placeholder="Хуримтлал /сараар/"
          className="w-40 ml-1"
          value={monthlyDeposit.toLocaleString()}
          onChange={(e) => {
            const input = e.target.value.replace(/[^\d,]/g, '');
            setMonthlyDeposit(Number(input.replace(/,/g, '')));
          }}
        />
        <label className='ml-4 mt-1' htmlFor="totalYear">Хугацаа /жил/:</label>
        <Input
          id="totalYear"
          type="number"
          placeholder="Хугацаа /жилээр/"
          className="w-40 ml-1"
          value={totalYear.toLocaleString()}
          onChange={(e) => {
            const input = e.target.value.replace(/[^\d,]/g, '');
            const twoDigitInput = input.slice(0, 2);
            setTotalYear(Number(twoDigitInput));
            //setTotalYear(Number(input.replace(/,/g, '')));
          }}
        />
        <label htmlFor="nav" className='ml-10 mt-1'>Жилийн өгөөж:</label>
        <span className='mt-1 ml-1 font-medium' id="nav">{rate}%</span>    
        </div>
        <div>
        <span className='text-xs ml-10'>*Ажилтнаас хуримтлуулах 50%</span>
        <span className='text-xs ml-[410px]'>*Энэхүү өгөөж нь амлалт биш бөгөөд зөвхөн тооцооллын зориулалтаар тавьсан болно.</span>
        </div>
    </div>
  );
}
export default TransactionList;