import React from 'react';
import { Card } from 'antd';
import dayjs from 'dayjs';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export interface IData {
  year: Date | string;
  endBalance: number;
  employeeAmount: number;
  companyAmount: number;
}
export interface IChartData {
  label: string;
  data: IData[];
}

interface IProps {
  rate: number;
  monthlyDeposit: number;
  totalYear: number;
}

const InvestReturnChart: React.FC<IProps> = ({ rate, monthlyDeposit, totalYear }) => {

  const calculateFundForecast = () => {
    totalYear = totalYear || 1;
    const years = Array.from(Array(totalYear).keys());
    const data: IData[] = years.map(year => ({
      year: new Date(new Date().getFullYear() + year, 0, 1),
      endBalance: calculateTotalFund(rate, monthlyDeposit*2, year),
      employeeAmount: calculateAdditionalTotal(monthlyDeposit, year),
      companyAmount: calculateAdditionalTotal(monthlyDeposit, year)
    }));

    return [
      {
        label: 'Нийт дүн',
        data,
      }
    ];
  };

  const calculateTotalFund = (rate: number, monthlyDeposit: number, years: number): number => {
    let total = 0;
    for (let i = 0; i < years; i++) {
      total = (total + monthlyDeposit * 12) * (1 + rate / 100);
    }
    return total;
  };

  const calculateAdditionalTotal = (monthlyDeposit: number, years: number): number => {
    return monthlyDeposit * 12 * years;
  };

  const renderTooltipContent = (props: any) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      const endBalance = payload.find((entry: any) => entry.dataKey === 'endBalance');
      const employeeAmount = payload.find((entry: any) => entry.dataKey === 'employeeAmount');
      const companyAmount = payload.find((entry: any) => entry.dataKey === 'companyAmount');

      return (
        <div className='bg-white p-4 w-72' style={{ border: '1px solid #c9b7b7' }}>
          <p>{`${dayjs(label).format('YYYY')} онд:`}</p>
          <table className="w-full border-2 border-black">
            <tbody>
              <tr>
                <td className="py-1 flex"><div className='h-2 w-2 mt-2 mr-2 rounded bg-chartColorA'></div>Ажилтнаас:</td>
                <td className="py-1">{employeeAmount && `${Math.floor(employeeAmount.value).toLocaleString()}`}</td>
              </tr>
              <tr>
                <td className="py-1 flex"><div className='h-2 w-2 mt-2 mr-2 rounded bg-chartColorA'></div>Компаниас:</td>
                <td className="py-1">{companyAmount && `${Math.floor(companyAmount.value).toLocaleString()}`}</td>
              </tr>
              <tr>
                <td className="py-1 flex"><div className='h-2 w-2 mt-2 mr-2 rounded bg-chartColorB'></div>Хуримтлалын хүч: </td>
                <td className="py-1">{endBalance && `${Math.floor(endBalance.value - employeeAmount.value - companyAmount.value).toLocaleString()}`}</td>
              </tr>
              <tr>
                <td>Үр дүн: </td>
                <td className="py-1 font-bold">{employeeAmount && `${Math.floor(endBalance.value).toLocaleString()}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  return (
    <Card>
      <div className='mt-8 h-[400px] ml-4 flex'>
        <BarChart
          width={700}
          height={400}
          data={calculateFundForecast()[0].data}
          margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
          barSize={40}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="year"
            tickFormatter={(value: any) => dayjs(value).format('YYYY')}
          />
          <YAxis
            tickFormatter={(value: any) => Math.floor(value).toLocaleString()}
          />
          <Tooltip content={renderTooltipContent} />
          <Legend />
          <Bar dataKey="employeeAmount" name="Ажилтанаас" stackId="a" fill="#57B7EF"/>
          <Bar dataKey="companyAmount" name="Компаниас" stackId="a" fill="#53BA6C"/>
          <Bar dataKey="endBalance" name="Хуримталын хүч" stackId="a" fill="#F9B719"/>
        </BarChart>
        <div className='w-[30%] text-xl ml-6'>
          <table className="w-full mt-10">
            <tbody>
              <tr style={{ borderBottom: '1px solid #c9b7b7' }}>
                <td className="pb-1 flex">Ажилтнаас:</td>
                <td className="py-1 font-medium">{Math.floor(calculateAdditionalTotal(monthlyDeposit, totalYear - 1)).toLocaleString()}₮</td>
              </tr>
              <tr style={{ borderBottom: '1px solid #c9b7b7' }}>
                <td className="pb-1 flex">Компаниас:</td>
                <td className="py-1 font-medium">{Math.floor(calculateAdditionalTotal(monthlyDeposit, totalYear - 1)).toLocaleString()}₮</td>
              </tr>
              <tr style={{ borderBottom: '1px solid #c9b7b7' }}>
                <td className="py-1 flex">Хуримтлалын хүч: </td>
                <td className="py-1 font-medium">{Math.floor(calculateTotalFund(rate, monthlyDeposit*2, totalYear - 1)-calculateAdditionalTotal(monthlyDeposit*2, totalYear - 1)).toLocaleString()}₮</td>
              </tr>
              <tr>
                <td className='font-bold'>Үр дүн: </td>
                <td className="py-1 font-bold">{Math.floor(calculateTotalFund(rate, monthlyDeposit*2, totalYear - 1)).toLocaleString()}₮</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}

export default InvestReturnChart;
