import dayjs from 'dayjs';

export const numberWithCommas = (x?: number | null) => {
  if (!x) {
    return '0';
  }

  return Math.round(x * 100 / 100).toString().replace(/\B(?=([\dA-Z]{3})+(?![\dA-Z]))/g, ",");
}

// export const calculatePOC = (newNav: number, oldNav: number) => {
//   return (newNav * 100 / oldNav - 100).toFixed(2);
// } old formula

export const calculatePOC = (startAmt: number, security: number,confirmedAt: string, nav: number) => {
  const currentAmt = security * nav;
  const sub = currentAmt - startAmt;
  const holdingPeriodYield = sub/startAmt;
  
  const tranDate = dayjs(confirmedAt);
  const currentDate = dayjs();
  const daysDifference = currentDate.diff(tranDate, 'day');
  const pow = 365/daysDifference;
  const yearlyYield = Math.pow(holdingPeriodYield+1, pow)-1
  const yearlyYieldPercent = yearlyYield*100;
  return yearlyYieldPercent.toFixed(2);
}
