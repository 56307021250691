import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, InputNumber, message, Popconfirm, Select } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DatePicker } from '../../components';
import { IEmployee } from '../../interfaces';

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} оруулна уу',
  types: {
    email: '${label} жинхэнэ биш байна',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

const statusList = [{
  value: 'paused',
  label: 'Түр зогсоосон',
  disable: false,
}, {
  value: 'deleted',
  label: 'Ажлаас гарсан',
  disable: true,
}, {
  value: 'created',
  label: 'Үүссэн',
  disable: false,
}, {
  value: 'confirmed',
  label: 'Баталгаажсан',
  disable: false,
},]

const EmployeeNew = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = async (dto: IEmployee) => {
    setSubmitting(true);
    try {
      const data = await (await apiClient.post(`/employee`, dto)).data;
      navigate(`/company/employees/${data.id}`)
    } catch (err: any) {
      message.error(err?.response?.data?.message || err?.message)
    }
    setSubmitting(false)
  }

  return (
    <Card style={{ margin: 10 }}>
      <h1>Шинээр ажилтан нэмэх</h1>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        disabled={submitting}
        validateMessages={validateMessages}
        style={{ padding: 16 }}
      >
        <Form.Item
          label="Овог"
          name="lastName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Нэр"
          name="firstName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Регистр"
          name="regNumber"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Имэйл"
          name="email"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Утас"
          name="phone"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Хуримтлал эхэлсэн огноо"
          name="startedAt"
          rules={[{ required: true }]}
          fieldId="n1l"
        >
          <DatePicker defaultValue={dayjs()} />
        </Form.Item>
        <Form.Item
          label="Төлөв"
          name={"status"}
        >
          <Select defaultValue={"created"}>
            {statusList.map((item) => (
              <Select.Option disabled={item.disable} value={item.value} key={item.value}>{item.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Цалингаас"
          name="fromSalary"
          rules={[{ required: true }]}
        >
          <InputNumber placeholder='Мөнгөн дүн' />
        </Form.Item>
        <Form.Item
          label="Байгууллагаас"
          name="fromCompany"
          rules={[{ required: true }]}
        >
          <InputNumber placeholder='Мөнгөн дүн' />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Хадгалах
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default EmployeeNew;