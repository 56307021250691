import { PDFDownloadLink } from '@react-pdf/renderer';
import { Card, Form, Input, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { apiClient } from '../../api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { companyGetMe } from '../../features/company/actions';
import { getNav } from '../../features/nav/navActions';
import { numberWithCommas } from '../../utils';
import Invoice, { InvoiceProps } from './Invoice';

const getColumns = (nav: number) => [
  {
    title: '№',
    render: (_: any, __: any, index: number) => index + 1
  },
  {
    title: 'Нэр',
    dataIndex: 'firstName',
    render: (_: any, record: any) => <Link to={`/company/employees/${record.employee.id}/transactions`}>{record.employee.firstName}</Link>,
  },
  {
    title: 'Регистр',
    dataIndex: 'regNumber',
    render: (_: any, record: any) => record.employee.regNumber,
  },
  {
    title: 'Цалингаас хуримтлуулсан',
    dataIndex: 'regNumber',
    render: (_: any, record: any) => numberWithCommas(record.employeeSecurities * record.nav) + '₮',
  },
  {
    title: 'Байгууллагаас хуримтлуулсан',
    dataIndex: 'regNumber',
    render: (_: any, record: any) => numberWithCommas(record.poolSecurity * record.nav) + '₮',
  },
  {
    title: 'Цалингаас хуримтлуулсан үр дүн',
    dataIndex: 'regNumber',
    render: (_: any, record: any) => numberWithCommas(record.employeeSecurities * nav) + '₮',
  },
  {
    title: 'Байгууллагаас хуримтлуулсан үр дүн',
    dataIndex: 'regNumber',
    render: (_: any, record: any) => numberWithCommas(record.poolSecurity * nav) + '₮',
  },
]

const Details = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const [transaction, setTransaction] = useState<any>();
  const [invoice, setInvoice] = useState<any>();
  const [form] = useForm();
  const [invoiceForm] = useForm();
  const [loading, setLoading] = useState(true);
  const { nav } = useAppSelector(state => state.nav);
  const { info } = useAppSelector((state) => state.company);
  const [pdf, setPdf] = useState<InvoiceProps>();

  useEffect(() => {
    const getTransaction = async () => {
      try {
        const { data } = await apiClient.get(`/transaction/${id}`);
        setTransaction(data.transaction);
        form.setFieldsValue({
          ...data.transaction,
          type: data.transaction.type === 'buy' ? 'Орлого' : 'Зарлага',
          confirmedAt: dayjs(data.transaction.confirmedAt).format('YYYY-MM-DD HH:mm:ss'),
          createdAt: dayjs(data.transaction.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        });
        setInvoice(data.invoice)
        invoiceForm.setFieldsValue({
          ...data.invoice,
          bank: 'Голомт банк',
        });

        dispatch(companyGetMe())

        setPdf({
          bank: 'Голомт банк',
          title: data.invoice.AccountName,
          accountNumber: data.invoice.AccountNumber,
          description: data.invoice.Description,
          name: data.transaction.description,
          employeeCount: data.transaction.transactionDetail.length,
          amount: data.transaction.amount,
          createdAt: data.transaction.createdAt,
          type: data.transaction.type,
          fee: data.fee,
        });

      } catch (err) {
      }
      setLoading(false)
    }

    getTransaction();
    dispatch(getNav())
  }, [id]);

  return (
    <Card style={{ margin: 10 }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ flex: 1, padding: '1rem' }}>
          <Form
            form={form}
            disabled={true}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item
              label='Үүсгэсэн огноо'
              name={'createdAt'}>
              <Input />
            </Form.Item>
            <Form.Item
              label='Гүйлгээний огноо'
              name='confirmedAt'>
              <Input />
            </Form.Item>
            <Form.Item
              label='Гүйлгээний дүн'
              name='amount'>
              <Input />
            </Form.Item>
            <Form.Item
              label='Төрөл'
              name={'type'}>
              <Input />
            </Form.Item>
          </Form>
        </div>
        <div style={{ flex: 1, padding: '1rem' }}>
          {invoice?.AccountNumber &&

            <Form
              form={invoiceForm}
              disabled={true}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
            >
              <Form.Item
                label='Банк'
                name={'bank'}>
                <Input />
              </Form.Item>
              <Form.Item
                label='Дансны дугаар'
                name='AccountNumber'>
                <Input />
              </Form.Item>
              <Form.Item
                label='Данс эзэмшигч'
                name='AccountName'>
                <Input />
              </Form.Item>
              <Form.Item
                label='Гүйлгээний утга'
                name={'Description'}>
                <Input />
              </Form.Item>
            </Form>
          }
        </div>
      </div>
      {pdf &&
        <div className='my-2 p-2'>
          <PDFDownloadLink document={<Invoice {...pdf} info={info} />} fileName={`invoice_${new Date().getTime()}.pdf`}>
            {({ blob, url, loading, error }) => (loading ? 'Уншиж байна...' : 'Нэхэмжлэх татах')}
          </PDFDownloadLink>
        </div>
      }
      <Spin size='large' spinning={loading} />
      {transaction &&

        <Table
          dataSource={transaction.transactionDetail || []}
          columns={getColumns(nav)}
          rowKey={"id"}
          loading={loading}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: [50, 100, 200]
          }} />
      }
    </Card>
  );
}

export default Details;